// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H1 from "../../../../styleguide/components/Heading/H1.res.js";
import * as H2 from "../../../../styleguide/components/Heading/H2.res.js";
import * as H3 from "../../../../styleguide/components/Heading/H3.res.js";
import * as Table from "../../../../styleguide/components/Table/Table.res.js";
import * as TableRow from "../../../../styleguide/components/Table/TableRow.res.js";
import * as TableBody from "../../../../styleguide/components/Table/TableBody.res.js";
import * as TableCell from "../../../../styleguide/components/Table/TableCell.res.js";
import * as TableHeader from "../../../../styleguide/components/Table/TableHeader.res.js";
import * as TableHeaderCell from "../../../../styleguide/components/Table/TableHeaderCell.res.js";
import Format from "date-fns/format";
import * as JsxRuntime from "react/jsx-runtime";
import * as SuspiciousIpEditForm from "./form/SuspiciousIpEditForm.res.js";
import * as SuspiciousIpEditScss from "./SuspiciousIpEdit.scss";
import * as DashboardSuspiciousIpsNavbar from "../commons/navbar/DashboardSuspiciousIpsNavbar.res.js";

var css = SuspiciousIpEditScss;

function formatDate(date) {
  return Format(new Date(date), "MMMM dd, yyyy, h:mm aaaa");
}

function fromOptionalDate(date, message) {
  if (date === null || date === undefined) {
    return message;
  } else {
    return Format(new Date(date), "MMMM dd, yyyy, h:mm aaaa");
  }
}

function SuspiciousIpEdit$default(props) {
  var suspiciousIp = props.suspiciousIp;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(DashboardSuspiciousIpsNavbar.make, {
                      currentLink: "Edit",
                      suspiciousIp: suspiciousIp
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(H1.make, {
                                      className: css.title,
                                      children: "Edit Suspicious IP"
                                    }),
                                JsxRuntime.jsx(H2.make, {
                                      className: css.subTitle,
                                      children: suspiciousIp.ipAddress
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsx("div", {
                                            children: "Created At: " + Format(new Date(suspiciousIp.createdAt), "MMMM dd, yyyy, h:mm aaaa"),
                                            className: css.createdAt
                                          }),
                                      className: css.data
                                    })
                              ],
                              className: css.titleContainer
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx(H3.make, {
                                              className: css.sectionTitle,
                                              children: "Edit Status"
                                            }),
                                        JsxRuntime.jsx(SuspiciousIpEditForm.make, {
                                              suspiciousIp: suspiciousIp
                                            })
                                      ],
                                      className: css.section
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx(H3.make, {
                                              className: css.sectionTitle,
                                              children: "History"
                                            }),
                                        JsxRuntime.jsxs(Table.make, {
                                              children: [
                                                JsxRuntime.jsx(TableHeader.make, {
                                                      children: JsxRuntime.jsxs(TableRow.make, {
                                                            children: [
                                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                                    children: "Column"
                                                                  }),
                                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                                    children: "Value"
                                                                  })
                                                            ]
                                                          })
                                                    }),
                                                JsxRuntime.jsxs(TableBody.make, {
                                                      children: [
                                                        JsxRuntime.jsxs(TableRow.make, {
                                                              children: [
                                                                JsxRuntime.jsx(TableCell.make, {
                                                                      children: "Date Blocked"
                                                                    }),
                                                                JsxRuntime.jsx(TableCell.make, {
                                                                      children: fromOptionalDate(suspiciousIp.blockedAt, "Not Set")
                                                                    })
                                                              ]
                                                            }),
                                                        JsxRuntime.jsxs(TableRow.make, {
                                                              children: [
                                                                JsxRuntime.jsx(TableCell.make, {
                                                                      children: "Invalid Attempts"
                                                                    }),
                                                                JsxRuntime.jsx(TableCell.make, {
                                                                      children: String(suspiciousIp.invalidAttempts)
                                                                    })
                                                              ]
                                                            })
                                                      ]
                                                    })
                                              ]
                                            })
                                      ],
                                      className: css.section
                                    })
                              ],
                              className: css.bodyContainer
                            })
                      ],
                      className: css.container
                    })
              ],
              className: css.pageContainer
            });
}

var $$default = SuspiciousIpEdit$default;

export {
  css ,
  formatDate ,
  fromOptionalDate ,
  $$default as default,
}
/* css Not a pure module */
