// extracted by mini-css-extract-plugin
export var bodyContainer = "SuspiciousIpEdit__bodyContainer__jKTbc";
export var column = "SuspiciousIpEdit__column__f9MBE";
export var container = "SuspiciousIpEdit__container__KqLMv";
export var createdAt = "SuspiciousIpEdit__createdAt__R1clv";
export var data = "SuspiciousIpEdit__data__QvDCt";
export var flex = "SuspiciousIpEdit__flex__PtMAs";
export var flexColumn = "SuspiciousIpEdit__flexColumn__pU_HK";
export var gap1 = "SuspiciousIpEdit__gap1__NRtpp";
export var gap2 = "SuspiciousIpEdit__gap2__orMDD";
export var gap3 = "SuspiciousIpEdit__gap3__3n0Am";
export var gap4 = "SuspiciousIpEdit__gap4__THWTT";
export var gap5 = "SuspiciousIpEdit__gap5__ACv8X";
export var pageContainer = "SuspiciousIpEdit__pageContainer__I8_xU";
export var row = "SuspiciousIpEdit__row__DMqOF";
export var section = "SuspiciousIpEdit__section__FbJDS";
export var sectionTitle = "SuspiciousIpEdit__sectionTitle__MiLlm";
export var title = "SuspiciousIpEdit__title__F1U0e";
export var titleContainer = "SuspiciousIpEdit__titleContainer__BDbrK";