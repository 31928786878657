// extracted by mini-css-extract-plugin
export var buttonContainer = "SuspiciousIpEditForm__buttonContainer__k1tid";
export var column = "SuspiciousIpEditForm__column__GZeb0";
export var flex = "SuspiciousIpEditForm__flex__iv8nV";
export var flexColumn = "SuspiciousIpEditForm__flexColumn__bWhuA";
export var form = "SuspiciousIpEditForm__form__JhyQt";
export var gap1 = "SuspiciousIpEditForm__gap1__bUPbU";
export var gap2 = "SuspiciousIpEditForm__gap2__oVfaz";
export var gap3 = "SuspiciousIpEditForm__gap3__bBIt_";
export var gap4 = "SuspiciousIpEditForm__gap4__FCzA5";
export var gap5 = "SuspiciousIpEditForm__gap5__Uz3UX";
export var row = "SuspiciousIpEditForm__row__DuRIt";